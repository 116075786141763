@import '_variables';

.Profile {
  &__description {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__field {
    margin-bottom: 20px;
  }

  &__label {
    color: $dark-2;
    font-size: 0.875rem;
    margin-bottom: 5px;
  }

  &__logo-input {
    opacity: 0;
  }

  &__file-upload {
    width: 200px;
    border-radius: 10px;
    padding: 30px;
    margin-top: 5px;
    margin-bottom: 30px;
    cursor: pointer;
    text-align: center;
    border: 1px dashed rgba(16, 24, 64, 0.2);
    background-color: rgba(245, 248, 249, 0.8);
  }

  &__file-preview {
    margin-top: 5px;
    margin-bottom: 20px;
    img {
      width: 82px;
      height: 82px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    button {
      margin-left: 15px;
    }
  }
}

.Container {
  background-color: $white;
  padding: 40px 30px;
  border-radius: 10px;
}
