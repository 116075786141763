@import '../../variables';

.Auth {
  border-radius: 10px;
  margin: 10% auto;
  width: 400px;

  &__container {
    padding: 30px;
    background-color: $white;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
    color: $dark-2;

    span {
      color: $base-green;
    }
  }

  &__link {
    font-weight: 500;
    margin-bottom: 30px;
  }

  &__field {
    margin-top: 20px;
  }

  &__strength-indicator {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &__btn {
    width: 100%;
    margin-top: 20px;
  }

  &__footer {
    margin-top: 20px;
    text-align: center;
  }
}
