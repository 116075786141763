@import 'colors';

.background {
  &--subtle-neutral {
    background: $neutral-3;
    color: $dark-2;
  }

  &--subtle-green {
    background: $light-green-2;
    color: $dark-green;
  }

  &--subtle-blue {
    background: $light-blue-2;
    color: $dark-blue;
  }

  &--subtle-red {
    background: $light-red-2;
    color: $dark-red;
  }

  &--subtle-orange {
    background: $light-orange-2;
    color: $dark-orange;
  }

  &--subtle-purple {
    background: $light-purple-2;
    color: $dark-purple;
  }

  &--subtle-yellow {
    background: $light-yellow-2;
    color: $dark-yellow;
  }

  &--subtle-teal {
    background: $light-teal-2;
    color: $dark-teal;
  }

  &--solid-neutral {
    background: $dark-2;
    color: $white;
  }

  &--solid-green {
    background: $base-green;
    color: $white;
  }

  &--solid-blue {
    background: $base-blue;
    color: $white;
  }

  &--solid-red {
    background: $base-red;
    color: $white;
  }

  &--solid-orange {
    background: $base-orange;
    color: $white;
  }

  &--solid-purple {
    background: $base-purple;
    color: $white;
  }

  &--solid-yellow {
    background: $base-yellow;
    color: $dark-yellow;
  }

  &--solid-teal {
    background: $light-teal-2;
    color: $dark-teal;
  }
}
