@import '_variables';

.ProfileSetup {
  &__logo {
    margin-top: 30px;
    margin-left: 20px;

    @media screen and (min-width: 768px) {
      margin-left: 20%;
    }
  }

  &__container {
    @media screen and (min-width: 480px) {
      width: 31.25rem;
    }

    padding: 15px;
    margin: 20px auto 50px;
  }
}

.rc-steps {
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
}

.rc-steps-item-content .rc-steps-item-title {
  opacity: 0.4;
  font-size: 1rem;
  font-weight: 600;
}

.rc-steps-dot .rc-steps-item-icon {
  width: 12px !important;
  height: 12px !important;
}

.rc-steps-dot .rc-steps-item-icon .rc-steps-item-icon,
.rc-steps-icon-dot,
.rc-steps-icon,
.rc-steps-item-icon {
  background: transparent !important;
}

.rc-steps-item-active .rc-steps-icon-dot,
.rc-steps-item-active .rc-steps-item-icon {
  background: $dark-1 !important;
  border-radius: 50% !important;
}

.rc-steps-item-active .rc-steps-item-title {
  opacity: 1;
  color: $dark-1 !important;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
  opacity: 1 !important;
  background-color: $base-green !important;
}

.rc-steps-item-finish .rc-steps-item-title {
  opacity: 1;
  color: $base-green !important;
}

.rc-steps-dot .rc-steps-item-tail:after {
  height: 2px;
  margin-top: 3px;
}

.rc-steps-item-process .rc-steps-item-tail:after,
.rc-steps-item-wait .rc-steps-item-tail:after {
  background: rgba($dark-1, 0.4) !important;
}
